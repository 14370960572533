@import './common/reset.css';

body {
  background: #EAF0F1;
  font: normal 14px/20px "Helvetica", arial, sans-serif;
  color: #2C3335;
}

main {
  padding: 16px;
}

.templateHint {
  font: bold 48px "Arial", Helvetica, sans-serif;
  color: #F3B431;
  text-align: center;
  margin-bottom: 16px;
}

.counterContainer h2 {
  display: block;
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
}

.counterContainer h2 span {
  vertical-align: middle;
}

.counterContainer h2 .counter {
  color: #AE1438;
  font-size: 48px;
}

.btnBlock {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  margin: 0 auto;
  align-content: center;
  justify-content: space-around;
}

.btn {
  border: 0;
  box-shadow: none;
  color: #FFF;
  cursor: pointer;
  padding: 8px;
  width: 130px;
  font-size: 16px;
  outline: none;
}

.resetBtn {
  width: 280px;
  background: #1287A5;
  margin-bottom: 16px;
}

.plusBtn {
  background: #019031;
}

.minusBtn {
  background: #B83227;
}